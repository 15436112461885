
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.interactiveWrapper {
  margin: 40px auto;
}

.interactive {
  width: 100%;
  position: relative;
  overflow: hidden;

  background: $black;

  &[data-fullscreen="true"] {
    position: fixed;
    z-index: 100;
    inset: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.sizer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreenButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.caption {
  margin: 20px 30px 0 30px;
  text-align: center;
  color: $gray-700;
  font-size: 0.9em;
}
