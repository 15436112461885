
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  @include trim-v-margins;
}
