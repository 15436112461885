
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
$three: 900px;
$two: 600px;
$one: 300px;

.patrons {
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  text-align: left;
  overflow: hidden;

  &[data-open="false"] {
    max-height: 140px;
    mask-image: linear-gradient(black 75%, transparent 100%);
    -webkit-mask-image: linear-gradient(black 75%, transparent 100%);
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: $three) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: $two) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $one) {
    grid-template-columns: 1fr;
  }
}
