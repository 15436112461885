
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.portrait {
  margin: 0 auto 20px auto;
  @include trim-v-margins;
  border-radius: 999px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: $page) {
  .portrait[data-sectionwidth="normal"][data-flip="false"] {
    margin-right: 40px;
    float: left;
  }

  .portrait[data-sectionwidth="normal"][data-flip="true"] {
    margin-left: 40px;
    float: right;
  }
}

@media (min-width: $page-narrow) {
  .portrait[data-sectionwidth="narrow"][data-flip="false"] {
    margin-right: 40px;
    float: left;
  }

  .portrait[data-sectionwidth="narrow"][data-flip="true"] {
    margin-left: 40px;
    float: right;
  }
}

.clearfix {
  clear: both;
  display: table;

  & + div + p {
    margin-top: 0;
  }
}
