
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.spoiler {
  background: $black;
  color: $black;
  transition: background $fast;

  &:hover,
  &:focus {
    background: none;
  }
}
