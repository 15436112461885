
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.container {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "title"
    "social"
    "featured";
  gap: 20px;
  max-width: 100%;
  overflow: hidden;
}

.title {
  grid-area: title;

  font-size: 25px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.social {
  grid-area: social;

  font-family: $sans;
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 25px;
}

.social_label {
  font-size: 16px;
  padding: 4px 8px
}

.featured {
  grid-area: featured;

  max-width: $page;
  margin-left: auto;
  margin-right: auto;
}

.itemButtons {
  display: flex;
  justify-content: flex-start;

  a {
    margin: 0 !important;
    padding: 8px 12px !important;
    min-width: unset !important;
    min-height: 38px !important;
    background: transparent !important;
    color: white !important;

    &:hover,
    &:focus {
      background: black !important;
    }
  }
}

.itemFigure {
  margin: 0;
}

.itemFigure > *:not(.itemCaption) {
  margin: 0 !important;
}

.itemCaption {
  text-align: center;
  color: $gray-200;
  margin-top: 12px;
  font-size: 24px;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.carousel {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "left slides right";
}

.slides {
  grid-area: slides;

  display: flex;
  align-items: flex-start;
  overflow: hidden;

  // Adjust gracefully when slides are different heights
  transition: max-height 400ms ease-in-out;
}

.slide {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  transition: transform 400ms ease-in-out;
}

.slide[data-visible="false"] {
  visibility: hidden;

  // Delay visibility so it waits to dissapear
  transition: transform 400ms ease-in-out, visibility 0ms linear 400ms;
}

.slide[data-visible="true"] {
  visibility: visible;
}

.arrowLeft,
.arrowRight {
  color: white;
  font-size: 48px;
  width: 50px;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.arrowLeft {
  grid-area: left;
}

.arrowRight {
  grid-area: right;
}

@media (max-width: 600px) {
  .carousel {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "slides slides"
      "left right";
    row-gap: 20px;
  }

  .arrowLeft {
    place-self: center end;
  }

  .arrowRight {
    place-self: center start;
  }
}

.video {
  display: block;
  width: 100%;
  height: auto;
  background: black;
}

.videoLink {
  display: block;
}


// TODO, too much copying from LessonVideo

.frame {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coverButton {
  position: relative;
  background: black;
}

.coverPlayButton {
  position: absolute;
  width: 5%;
  min-width: 40px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: rgba(33, 33, 33, 0.8);
  transition: color 100ms ease-in-out;
}

.coverButton:hover .coverPlayButton,
.coverPlayButton:active .coverPlayButton {
  color: red;
}