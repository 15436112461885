
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}

.teamMember {
    text-align: center;
    width: 200px;
    padding-top: 1.5rem;   

    .headshot {
        width: 100%;
        aspect-ratio: 1 / 1;
        border-radius: 999px;
        margin-bottom: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .name {
        font-family: $sans;
        text-align: center;
        margin: 0;
        color: #333;
    }

    .role {
        margin: 5px 0;
        font-size: 14px;
        color: #666;
        min-height: 3lh;
        text-align: left;
        align-self: flex-start;
    }

    .websiteLink {
        color: #007bff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.link {
    color: #7b7b7b;
    text-decoration: none;
    font-size: 24px; // Adjust size as needed
}

.link:hover {
    color: #007bff;
}