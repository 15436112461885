
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.answerHeader {
  text-align: left;
  font-weight: $semi-bold;
  margin-bottom: 4px;
}

.yours {
  position: relative;
  margin-bottom: 16px;
}

.input {
  width: 100%;
  min-height: 64px;
  resize: vertical;
  font: inherit;
  padding: 8px;
  line-height: 1.6;
  border: 2px solid $gray-700;
  margin-bottom: 8px;

  &:focus {
    border-color: $blue-400;
    outline: none;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.submit {
  margin: 0;
  padding: 8px 16px !important;
  min-height: 0 !important;
}

.tooltip {
  width: 28px;
  height: 28px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $sans;
  color: $gray-500;
  border: 2px dashed $gray-300;
  border-radius: 9999px;
  user-select: none;
}

.ours {
  padding: 8px;
  border: 2px solid transparent;
  transition: all 200ms linear;

  &[data-visible="false"] {
    .explanation {
      filter: blur(8px);
      user-select: none;
      pointer-events: none;

      img,
      video {
        filter: blur(36px);
      }
    }
  }

  &[data-visible="true"] {
    background: $gray-100;
    border-color: $gray-700;
  }
}

.explanation {
  transition: filter 200ms linear;

  p {
    line-height: 1.6;
    text-align: left;
  }

  img,
  video {
    transition: filter 200ms linear;
  }
}
