
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
$tablet: 1200px;
$phone: 700px;

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  background: $black;
  color: $white;
  font-family: $sans;
  overflow: hidden;
  box-shadow: 0 0 10px #00000040;

  &[data-big="true"] {
    min-height: 250px;
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  @include hover;
  z-index: 1;

  @media (max-width: $phone) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}

.button {
  display: none;

  @media (max-width: $phone) {
    display: flex;
    width: 100%;
    padding: 10px;
    color: white;

    span {
      margin-left: 10px;
    }
  }
}

.link {
  padding: 10px 15px;
  color: $white;
  text-decoration: none;
  text-align: center;

  @media (max-width: $phone) {
    width: 100%;
  }
}

.break {
  flex-grow: 1;

  @media (max-width: $tablet) {
    flex-grow: 0;
  }
}

.title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: $white;
  opacity: 1 !important;
  text-align: center;
  text-decoration: none;
  z-index: 2;

  @media (max-width: $tablet) {
    position: static;
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

.header[data-big="true"] .title {
  flex-direction: column;
}

.text {
  margin-left: 20px;
  font-family: $serif;
  font-size: 1.8rem;
  letter-spacing: 2px;

  span {
    opacity: 0;
    @include fade-in;
  }

  @for $i from 1 through 11 {
    span:nth-child(#{$i}) {
      animation-delay: #{$i * 0.075}s;
    }
  }
}

.header[data-big="true"] .text {
  margin-left: 0;
  margin-top: 20px;
}

@media (max-width: $phone) {
  .nav[data-open="true"] .button {
    margin-bottom: 10px;
  }

  .nav[data-open="false"] .link {
    display: none;
  }
}

@media print {
  .header {
    display: none;
  }
}
