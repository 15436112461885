
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.question {
  margin: 20px 0;
  @include trim-v-margins;
  text-align: left;
}

.text {
  font-weight: $semi-bold;
}

.choices {
  margin: 10px 0;
  @include trim-v-margins;
}

.choice {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  transition: background $fast;
  cursor: pointer;

  &:hover {
    background: $light-blue-100;
  }

  mjx-container {
    display: inline !important;
  }
}

.choice[data-highlight="true"] {
  background: $green-100;
}

.radio_input {
  opacity: 0;
  position: absolute;
  z-index: -2;
}

.radio_input:focus-visible + .radio_svg {
  background: $gray-200;
}

.radio_svg {
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  flex-shrink: 0;
  transition: background $fast;
}

.radio_input + .radio_svg:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url("/images/icons/radio-unchecked.svg");
}

.radio_input:checked + .radio_svg:after {
  background: url("/images/icons/radio-checked.svg");
}

.choice_text {
  padding: 10px;
  flex-grow: 1;
}

.choice_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  opacity: 0;
  color: $green-500;
}

.choice[data-highlight="true"] .choice_icon {
  opacity: 1;
}

.result {
  margin: 10px 0;
  @include trim-v-margins;
  align-items: center;

  & > span {
    padding: 5px 10px;
  }
}

.explanation {
  padding: 20px;
  background: $gray-100;
}
