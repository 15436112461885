
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.tabs {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.search {
  margin-right: 0;
  display: flex;
  align-items: stretch;
  position: relative;

  & > i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    pointer-events: none;
  }

  & > input {
    flex-grow: 1;
    padding: 10px 0;
    padding-left: 44px;
    font: inherit;
    background: $gray-200;
    border: none;
  }

  &[data-active="true"] > input {
    background: $gray-300;
  }
}

.no_results {
  margin: 20px;
  font-style: italic;

  p {
    text-align: center;
  }
}

.topicGrid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.topic_card {
  position: relative;
  color: inherit;
  font: inherit;
  text-decoration: none;

  display: inline-flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 0;
  font-family: $sans;
  text-decoration: none;
  z-index: 1;

  &[data-open="true"] {
    width: 100%;
  }

  &:hover {
    background: $light-blue-100;
  }
}

.image {
  $width: 225px;
  width: $width;
  height: 9 * math.div($width, 16);
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .image {
    display: none;
  }
  .topicGrid {
    flex-direction: column;
  }
}

.title {
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: $medium;
  font-size: 16px;
}