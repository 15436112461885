
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.bookShelf {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px;
}

.book {
  display: block;
  margin: 8px;
}

.title {
  justify-content: center;
  font-size: 16px;
  margin: 8px;
}

.authors {
  justify-content: center;
  font-size: 14px;
  margin: 8px;
}