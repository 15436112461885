
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.feature_card {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  margin: 10px;
  padding: 20px;
  color: $black;
  font-family: $sans;
  text-decoration: none;
  transition: background $fast;

  &:hover {
    background: $light-blue-50;
  }
}

.image {
  display: block;
  width: 100%;
  margin-bottom: 10px;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.title {
  font-weight: $semi-bold;
  margin-bottom: 10px;
}
