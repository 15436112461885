
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.preview {
  overflow: hidden;

  &[data-open="false"] {
    max-height: 175px;
    mask-image: linear-gradient(black 75%, transparent 100%);
    -webkit-mask-image: linear-gradient(black 75%, transparent 100%);
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}
