
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
