
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
$wrap: 600px;

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: $black;
  color: $white;
  font-family: $sans;
  overflow: hidden;
  box-shadow: 0 0 10px #00000040, inset 0 0 200px 0 $black;

  @media (max-width: $wrap) {
    flex-direction: column-reverse;
  }
}

.copyright {
  text-align: center;

  @media (max-width: $wrap) {
    margin-top: 10px;
  }
}

@media print {
  .footer {
    display: none;
  }
}
