
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
  @include trim-v-margins;
}

.controls {
  max-width: 100%;
  text-align: right;

  button {
    margin: 0 !important;
    padding: 5px 10px !important;
    min-width: unset !important;
    min-height: unset !important;
  }
}

.frame {
  max-width: 100%;
}

.image {
  display: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.video {
  display: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.figure[data-show="image"] .image {
  display: block;
}

.figure[data-show="video"] .video {
  display: block;
}

.caption {
  margin: 20px 30px 0 30px;
  text-align: center;
  color: $gray-700;
  font-size: 0.9em;
}
