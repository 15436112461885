
@use "sass:math";
@import "styles/palettes.scss";
@import "styles/theme.scss";
@import "styles/mixins.scss";
    
.podcastEpisode {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 16px;
  margin: -16px;
  margin-bottom: 16px;
}

.podcastEpisode:hover {
  background: $gray-200;
}

.thumbnail {
  width: 200px;
  margin-right: 24px;
}

.info {
  text-align: left;
  margin: 10px;
}

.title {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 4px;
  margin-top: 8px;
}

.description {
  color: $gray-700;
  font-size: 15px;
}

@media (max-width: 600px) {
  .description {
    font-size: 0px;
  }
}